const BannerComponentDataRowSubTypeEnums = {
  BANNER: "threeBanner",
  CUSTOM_BANNER: "customBanner",
  TEMPLATE_BANNER: "templateBanner"
};

const MAX_SHOW_LENGTH_CUSTOM_BANNER = 3;
const MIN_SHOW_LENGTH_CUSTOM_BANNER = 2;
const MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER = 1;
export {
  BannerComponentDataRowSubTypeEnums,
  MAX_SHOW_LENGTH_CUSTOM_BANNER,
  MIN_SHOW_LENGTH_CUSTOM_BANNER,
  MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER
};

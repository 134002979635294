import React from "react";
import styles from "./BannerCarousel.scss";
import ImageCaption from "./common/ImageCaption";
import { handleSafeArea, handleSafeAreWidth } from "./constants";
import useCheckMobileScreen from "../../../../../utils/hooks/useCheckMobileScreen";
import classNames from "classnames";
import TaggedProductPopover from "../TaggedProductPopover/loadable";

const BannerCarousel = ({ banner, clickEventHandler, size, hasAdditionalInfo = true, bannerTagEvents }) => {
  const { isMobile } = useCheckMobileScreen();

  const urlToString = () => {
    const ctrlRegex = /https?:\/\/(?:www.)?/g;
    if (banner.url.match(ctrlRegex) !== null) {
      return banner.url;
    } else {
      if (banner.url.includes("www.")) {
        return `https://${banner.url}`;
      } else {
        return `https://www.${banner.url}`;
      }
    }
  };
  const findTaggedProduct = tag => banner.products?.find(product => product.variantList[0].sku === tag.sku);
  const findTaggedProductIndex = tag => banner.products?.findIndex(product => product.variantList[0].sku === tag.sku);
  const renderTags = banner => {
    return banner.tags.map(tag => {
      const taggedProduct = findTaggedProduct(tag);
      return (
        taggedProduct && (
          <TaggedProductPopover
            tag={tag}
            isMobile={isMobile}
            product={taggedProduct}
            prodIndex={findTaggedProductIndex(tag)}
            {...bannerTagEvents}
          />
        )
      );
    });
  };
  const bannerHasTaggedProduct = banner.hasOwnProperty("type") && banner.type === "product" && banner.tags?.length > 0;
  if (!hasAdditionalInfo) {
    return (
      <a
        href={bannerHasTaggedProduct ? null : urlToString()}
        onClick={bannerHasTaggedProduct ? null : clickEventHandler}
        className={classNames(styles.Container, { [styles.taggedBanner]: bannerHasTaggedProduct })}
      >
        {bannerHasTaggedProduct && renderTags(banner)}
        <figure className={styles[(banner?.imageSizeRatio)]}>
          <img
            loading='lazy'
            className={[
              styles.CarouselImage,
              styles.CarouselImage_TemplateBanner,
              handleSafeAreWidth(banner.imageSizeRatio, size, isMobile)
            ].join(" ")}
            src={banner?.imageUrl}
            alt='Banner'
          />
        </figure>
      </a>
    );
  }

  return (
    <a
      href={bannerHasTaggedProduct ? null : urlToString()}
      onClick={bannerHasTaggedProduct ? null : clickEventHandler}
      className={classNames(styles.Container, { [styles.taggedBanner]: bannerHasTaggedProduct })}
    >
      {bannerHasTaggedProduct && renderTags(banner)}
      <figure className={styles[(banner?.imageSizeRatio)]}>
        <img
          loading={"lazy"}
          className={[
            handleSafeArea(banner?.imageTitleLocation),
            handleSafeAreWidth(banner.imageSizeRatio, size, isMobile)
          ].join(" ")}
          src={banner?.imageUrl}
          alt={banner?.title ? banner?.title : "Banner"}
        />
        {!bannerHasTaggedProduct && banner?.hasOverlay && banner?.showHeadline && <div className={styles.overlay} />}
        {!bannerHasTaggedProduct && (
          <ImageCaption
            title={banner?.title}
            subTitle={banner?.subTitle}
            imageTitleLocation={banner?.imageTitleLocation}
            showHeadline={banner?.showHeadline}
            titleOrder={banner?.titleOrder}
          />
        )}
      </figure>
    </a>
  );
};

export default BannerCarousel;

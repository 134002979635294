import React from "react";
import BannerRow from "../BannerRow/BannerRow";
import TemplateBannerRow from "../TemplateBannerRow/TemplateBannerRow";
import { BannerComponentDataRowSubTypeEnums } from "../BannerRow/contants";

const BannerController = ({ row, merchantDetail, index, hbAdultValue, backgroundType }) => {
  if (row.subType === BannerComponentDataRowSubTypeEnums.TEMPLATE_BANNER)
    return <TemplateBannerRow key={row.id} row={row} merchantDetail={merchantDetail} index={index} />;
  return (
    <BannerRow
      key={row.id}
      row={row}
      merchantDetail={merchantDetail}
      index={index}
      hbAdultValue={hbAdultValue}
      backgroundType={backgroundType}
    />
  );
};

export default BannerController;

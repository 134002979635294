const merchantTabNameEnums = {
  MERCHANT_ROW: "MerchantRow",
  MORDOR_PRODUCTS: "MordorProducts",
  ABOUT_SELLER: "AboutSeller",
};
const queryTabNameEnums = {
  ALL_PRODUCTS: "allproducts",
  PROFILE: "profile",
  STORE: "store",
};
const QUERY_TO_TABS = {
  [queryTabNameEnums.STORE]: merchantTabNameEnums.MERCHANT_ROW,
  [queryTabNameEnums.ALL_PRODUCTS]: merchantTabNameEnums.MORDOR_PRODUCTS,
  [queryTabNameEnums.PROFILE]: merchantTabNameEnums.ABOUT_SELLER
}
const TABS_TO_QUERY = {
  [merchantTabNameEnums.MERCHANT_ROW]: queryTabNameEnums.STORE,
  [merchantTabNameEnums.MORDOR_PRODUCTS]: queryTabNameEnums.ALL_PRODUCTS,
  [merchantTabNameEnums.ABOUT_SELLER]: queryTabNameEnums.PROFILE
}


const merchantTabLabelEnums = {
  MERCHANT_ROW: "Mağaza",
  MORDOR_PRODUCTS: "Tüm ürünler",
  ABOUT_SELLER: "Satıcı profili",
};

const merchantTabs = [
  {
    name: merchantTabNameEnums.MERCHANT_ROW,
    label: merchantTabLabelEnums.MERCHANT_ROW,
  },
  {
    name: merchantTabNameEnums.MORDOR_PRODUCTS,
    label: merchantTabLabelEnums.MORDOR_PRODUCTS,
  },
  {
    name: merchantTabNameEnums.ABOUT_SELLER,
    label: merchantTabLabelEnums.ABOUT_SELLER,
  },
];

export {merchantTabNameEnums, QUERY_TO_TABS,TABS_TO_QUERY, merchantTabLabelEnums, queryTabNameEnums, merchantTabs};

import HBEventBusService from "../../../../services/hbBusService";

const voltran = require("/home/node/app/node_modules/voltranjs/src/index");

import React from "react";
import { renderRowComponents } from "../../MerchantRow/helpers/render";

import ROUTE_PATHS from "../../../../routes/routeConstants";
import { getInitialData } from "../helpers/initial";
import { RowContextProvider } from "../../../../contexts/RowContext";
import { merchantTabs } from "../../MerchantTabular/constants";

const MerchantLayout = ({ initialState }) => {
  const { merchantDetail, backgroundType, desktopRows, desktopIds, hbAdultValue, location } = initialState.data;

  return (
    <RowContextProvider>
      <>
        {desktopRows &&
          Object.keys(desktopRows)?.length > 0 &&
          desktopIds?.length > 0 &&
          desktopIds?.map((id, index) =>
            renderRowComponents(
              desktopRows[id],
              index,
              merchantDetail,
              backgroundType,
              () => HBEventBusService().onTabChange(merchantTabs[1].name),
              hbAdultValue,
              location
            )
          )}
      </>
    </RowContextProvider>
  );
};

const component = voltran.default.withBaseComponent(MerchantLayout, ROUTE_PATHS.MERCHANT_LAYOUT);

component.services = [
  voltran.default.SERVICES.merchantContentApi
];

component.getInitialState = getInitialData;
component.getSeoState = initialState => {
  const { desktopIds } = initialState;
  return { showLayoutTab: desktopIds.length > 0 };
};
export default component;

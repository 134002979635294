import React from "react";
import ProductRow from "../../../components/RowComponents/ProductRow/ProductRow";
import CouponRow from "../../../components/RowComponents/CouponRow/CouponRow";
import CampaignRow from "../../../components/RowComponents/CampaignRow/CampaignRow";
import CommentRow from "../../../components/RowComponents/CommentRow/CommentRow";
import VideoRow from "../../../components/RowComponents/VideoRow/VideoRow";
import BannerController from "../../../components/RowComponents/BannerController/BannerController";

export const renderRowComponents = (
  row,
  index,
  merchantDetail,
  backgroundType,
  setTabOtherProducts,
  hbAdultValue,
  location
) => {
  /** IMAGE COMMENT COMPONENTS */
  if (row.type === "comment") {
    return <CommentRow key={row.id} row={row} index={index} merchantDetail={merchantDetail} />;
  }
  /** PRODUCT COMPONENTS */
  if (row.type === "product") {
    // Validation
    // If the product's data is lower than 6, return null.
    if (row.data.length < 6 && row.subType !== "otherProducts") return null;

    return (
      <ProductRow
        key={row.id}
        row={row}
        merchantDetail={merchantDetail}
        index={index}
        hbAdultValue={hbAdultValue}
        backgroundType={backgroundType}
        setTabOtherProducts={setTabOtherProducts}
        location={location}
      />
    );
  }

  /** COUPON COMPONENTS */
  if (row.type === "coupon") {
    return (
      <CouponRow
        key={row.id}
        row={row}
        merchantDetail={merchantDetail}
        index={index}
        hbAdultValue={hbAdultValue}
        backgroundType={backgroundType}
      />
    );
  }

  /** BANNER COMPONENTS */
  if (row.type === "banner") {
    const filteredRowData = row.data.filter(banner => banner.tags !== null);
    return (
      <BannerController
        key={row.id}
        row={{ ...row, data: filteredRowData }}
        merchantDetail={merchantDetail}
        index={index}
        hbAdultValue={hbAdultValue}
        backgroundType={backgroundType}
      />
    );
  }

  /** CAMPAIGN COMPONENTS */
  if (row.type === "campaign") {
    return (
      <CampaignRow
        key={row.id}
        row={row}
        merchantDetail={merchantDetail}
        index={index}
        hbAdultValue={hbAdultValue}
        backgroundType={backgroundType}
      />
    );
  }

  /** VIDEO COMPONENTS  * */
  if (row.type === "video") {
    return (
      <VideoRow
        key={row?.id}
        row={row}
        merchantDetail={merchantDetail}
        index={index}
        hbAdultValue={hbAdultValue}
        backgroundType={backgroundType}
      />
    );
  }
};

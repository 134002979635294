import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./Player.scss";
import { PauseIcon, PlayIcon } from "../../../../../../assets/components";
import { Slider } from "./Slider";
import { timeToTimeString } from "./utils/timeToTimeString";
import ChevronRight from "../../../../../../assets/components/chevronRight";
import { handleCheckUrl } from "../../../../../../utils/helpers/utils";

import useLazyLoad from "Utils/hooks/useLazyLoad";
import ProductTag from "./ProductTag/loadable";

const Player = ({
  source,
  poster,
  onShowModal,
  showModal,
  title,
  url,
  showProductRedirectButton = "current",
  flat,
  onShowTitle,
  clickEvent,
  rowLength,
  products,
  rowIndex,
  bannerIndex,
  merchantDetail,
  row
}) => {
  const [player, isIntersecting] = useLazyLoad();
  const interval = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleTimeChange = useCallback((time, offsetTime) => {
    if (!player.current?.currentTime) {
      return;
    }
    player.current.currentTime = time / 1000;
    setCurrentTime(time);
  }, []);

  const handlePlay = () => {
    interval.current = setInterval(() => {
      setCurrentTime((player.current?.currentTime || 0) * 1000);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(interval.current);
  };

  const handleDataLoaded = () => {
    setMaxTime((player.current?.duration || 0) * 1000);
  };

  const handleProgress = () => {
    const buffer = player?.current?.buffered;

    if (((buffer?.length > 0 && player.current?.duration) || 0) > 0) {
      let currentBuffer = 0;
      const inSeconds = player.current?.currentTime || 0;

      for (let i = 0; i < buffer.length; i++) {
        if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
          currentBuffer = i;
          break;
        }
      }

      setProgress(buffer.end(currentBuffer) * 1000 || 0);
    }
  };

  useEffect(() => {
    if (!player) {
      return;
    }

    setMaxTime((player.current?.duration || 0) * 1000);
    player.current?.addEventListener("play", handlePlay);
    player.current?.addEventListener("pause", handlePause);
    player.current?.addEventListener("loadeddata", handleDataLoaded);
    player.current?.addEventListener("progress", handleProgress);
  }, [player]);
  const maxTimeString = timeToTimeString(maxTime, maxTime, 0, "0:", "00:");
  const currentTimeString = timeToTimeString(maxTime, currentTime, 0, "0:", "00:");
  const hasProducts = products?.length > 0;
  const onPlayIconClicked = () => {
    setHover(true);
    player?.current?.play();
  };
  return (
    <div className={styles.Container} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {hover && <div className={styles.VideoGradientHover}></div>}
      {rowLength > 1 && showProductRedirectButton !== "modal" && (
        <img loading='lazy' className={styles.VideoThumb} src={poster} alt={title || "Video Banner"} />
      )}
      {(rowLength === 1 || showProductRedirectButton === "modal") && (
        <video
          autoPlay={showProductRedirectButton === "modal"}
          ref={player}
          className={cx(styles.Video, {
            [styles.VideoDefault]: !flat,
            [styles.VideoFlat]: flat
          })}
          onEnded={() => setCurrentTime(0)}
          poster={poster}
          preload='metadata'
          onLoadedMetadata={() => setLoading(false)}
          onPlay={() => {
            setPlaying(true);
            onShowTitle && onShowTitle(false);
          }}
          onPause={() => setPlaying(false)}
        >
          {isIntersecting && <source src={source} type='video/mp4' />}
        </video>
      )}

      {!loading && playing && hover && (
        <div
          className={cx(styles.SliderWrapper, {
            [styles.ModalPosition]:
              (showProductRedirectButton === "modal" && !hasProducts && (title || url)) ||
              (showProductRedirectButton === "current" && !hasProducts && !title && url),
            [styles.ModalProductPosition]:
              (showProductRedirectButton === "modal" && !url && (title || hasProducts)) ||
              (showProductRedirectButton === "current" && !url && !title && hasProducts),
            [styles.CurrentPosition]: showProductRedirectButton === "current" && title && url,
            [styles.CurrentProductPosition]: showProductRedirectButton === "current" && !url && hasProducts
          })}
        >
          {!showModal && <span className={styles.WithoutModalTitle}>{title}</span>}
          <div className={styles.SliderWrapperInner}>
            <span className={[styles.SliderBlock, styles.SliderLeft].join(" ")}>{currentTimeString}</span>
            <Slider
              max={maxTime}
              currentTime={currentTime}
              bufferTime={progress}
              onChange={handleTimeChange}
              limitTimeTooltipBySides
              secondsPrefix='00:'
              minutesPrefix='0:'
            />
            <span className={[styles.SliderBlock, styles.SliderRight].join(" ")}>{maxTimeString}</span>
          </div>
        </div>
      )}

      {!loading && !playing && (
        <PlayIcon
          role='button'
          className={styles.PlayIcon}
          onClick={() => {
            showModal ? onShowModal() : onPlayIconClicked();
            clickEvent?.();
          }}
        />
      )}
      {!loading && playing && hover && (
        <PauseIcon role='button' className={styles.PauseIcon} onClick={() => player?.current?.pause()} />
      )}
      {loading && <span className={[styles.Spinner, styles.Animated].join(" ")} />}
      {hover && playing && showProductRedirectButton === "current" && (
        <>
          {url && !hasProducts && (
            <a
              href={handleCheckUrl(url)}
              title='Ürüne git'
              className={cx({
                [styles.WithoutModalProductRedirectButton]: title && url,
                [styles.WithModalProductRedirectButton]: !title && url
              })}
            >
              <span>Ürüne git</span>
              <ChevronRight color='white' />
            </a>
          )}
          {!url && hasProducts && (
            <ProductTag
              products={products}
              bannerIndex={bannerIndex}
              rowIndex={rowIndex}
              row={row}
              merchantDetail={merchantDetail}
            />
          )}
        </>
      )}

      {hover && playing && showProductRedirectButton === "modal" && (
        <>
          {!hasProducts && (
            <>
              {url && (
                <a
                  href={handleCheckUrl(url)}
                  title='Ürüne git'
                  className={cx({ [styles.WithModalProductRedirectButton]: url })}
                >
                  <span>Ürüne git</span>
                  <ChevronRight color='white' />
                </a>
              )}
            </>
          )}
          {hasProducts && (
            <ProductTag
              products={products}
              bannerIndex={bannerIndex}
              rowIndex={rowIndex}
              row={row}
              merchantDetail={merchantDetail}
            />
          )}
        </>
      )}
    </div>
  );
};

Player.propTypes = {
  source: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  onShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  showProductRedirectButton: PropTypes.oneOf(["current", "modal"]),
  flat: PropTypes.bool,
  onShowTitle: PropTypes.func,
  clickEvent: PropTypes.func
};
export default Player;

import { checkMerchantIdExists, checkMerchantIsActiveOrNot } from "../../MerchantRow/helpers/merchant";
import { getMerchantDataWithParamsByIdInternal } from "../../../../services/merchantService";
import { getPublishedLayoutByMerchantIdInternal } from "../../../../services/layoutService";
import { throwError } from "Utils/errorHandler/errorHandler";
import { MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER } from "../../../components/RowComponents/BannerRow/contants";
import { SPECIAL_COMPONENTS_WITH_MERCHANT_TAGS } from "Utils/constants";

export const getInitialData = async (voltranApiManager, context) => {
  // Do not remove "preview"
  /* eslint-disable-next-line no-unused-vars */
  const { preview, merchantId, isBot, ...rest } = context.query;
  const ABTestingParams = {
    experimentCookie: rest?.experimentCookie,
    anonId: rest?.anonId,
    userId: rest?.userId
  };
  const isBotBool = (isBot && isBot === "true");
  checkMerchantIdExists(merchantId);
  // const searchParamsExist = checkSearchParamExistsExceptAllowed(context.query);

  try {
    // Add this get operation to settleAll after SF Team gives merchant name directly
    const requestArray = [
      // Get merchant data by id
      getMerchantDataWithParamsByIdInternal(merchantId, voltranApiManager, context.cookies),
      // Get background info of the merchant
      getPublishedLayoutByMerchantIdInternal(
        merchantId,
        false,
        voltranApiManager,
        context.cookies,
        true,
        ABTestingParams,
        isBotBool
      )
    ];

    const response = await Promise.allSettled(requestArray);
    const merchantRowBackground = response[1]?.value?.data?.data?.background;
    const isThereBgColor = merchantRowBackground?.color !== "#FFFFFF" && !!merchantRowBackground?.color;
    const isThereBg = isThereBgColor || !!merchantRowBackground?.imageUrl;
    const isContentDisable = response[0]?.value?.data?.data?.tagList?.some(item =>
      item.includes("content-disable-row-image")
    );

    const backgroundType = () => {
      if (isThereBgColor) {
        return "colorBg";
      }
      if (isContentDisable) {
        return "blankBg";
      }
      return isThereBg ? "imageBg" : "blankBg";
    };

    const merchantDetail = response[0]?.value?.data?.data;
    const rows = response[1]?.value?.data?.data?.rows;
    const ids = response[1]?.value?.data?.data?.ids;
    let filteredIds = [];
    let filteredRows = {};
    const desktopIds = [];
    const desktopRows = {};
    filteredRows = { ...rows };
    filteredIds = ids?.length > 0 ? [...ids] : [];
    SPECIAL_COMPONENTS_WITH_MERCHANT_TAGS.forEach(item => {
      if (merchantDetail?.tagList.includes(item.tag) === item.willBeDelete) {
        ids?.forEach(id => {
          if (item.componentSubTypeList.includes(rows[id]?.subType)) {
            delete filteredRows[id];
            filteredIds = filteredIds.filter(item => item !== id);
          }
        });
      }
    });

    filteredIds?.forEach(id => {
      if (
        filteredRows[id]?.data?.length > MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER ||
        filteredRows[id]?.type === "coupon" ||
        filteredRows[id]?.type === "campaign" ||
        filteredRows[id]?.type === "video" ||
        filteredRows[id]?.data?.every(item => item?.imageSizeRatio === "horizontal")
      ) {
        desktopRows[id] = filteredRows[id];
        desktopIds.push(id);
      }
    });

    const data = {
      merchantId,
      merchantDetail,
      merchantRowBackground: response[1]?.value?.data?.data?.background,
      desktopRows,
      desktopIds,
      backgroundType: backgroundType(),
      hbAdultValue: context.query?.hb_adult,
      location: context
    };

    if (data) {
      checkMerchantIsActiveOrNot(data);

      return data;
    }
  } catch (error) {
    throwError(error, context);
  }
};
